<!-- 添加通讯录弹窗 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'修改通讯录':'添加通讯录'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px">
      <el-row :gutter="18">
        <el-col :span="24">
          <el-form-item label="类型:" prop="type">
            <el-select
                clearable
                v-model="form.type"
                placeholder="请选择"
                class="ele-fluid">
              <el-option
                v-for="(item) in dict.type.addressbooktype"
                :label="item.label"
                :value="parseInt(item.value)"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="姓名:" prop="realname">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.realname"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="电话:" prop="phone">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.phone"
                placeholder="请输入"/>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
//引入的接口
import {Addaddress_book, Editaddress_book} from "@/api/custom";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // 父级id
    id: Number
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 验证规则
      rules: {
        type:[
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phone:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在5 到 20个字符', trigger: 'blur' }
          // {
          //   pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          //   message: "请输入正确的手机号码",
          //   trigger: "blur"
          // }
        ]
      },

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            // 新增修改都需要添加这个id
            this.form.channel_id = this.id;
            Addaddress_book(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editaddress_book(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped>
</style>
